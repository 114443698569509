import { EODStrike, IBSStrike, SOTOStrike, Screen1, WING1, WING2, WING3, WING4, WING5, WING6, WING7 } from "../assets";

export const newsData: INews[] = [
  {
    type: "Record",
    title: "Wing 1",
    text: "Full Wing 5 men in 20:45",
    image: WING1,
    date: new Date(2024, 10, 7),
    recordName: "1",
  },
  {
    type: "Record",
    title: "Wing 6",
    text: "Twin Largos 5 men in 4:38",
    image: WING6,
    date: new Date(2024, 9, 7),
    recordName: "6",
  },
  {
    type: "Record",
    title: "Wing 4",
    text: "Deimos 4 Men in 6:04",
    image: WING4,
    date: new Date(2024, 7, 19),
    recordName: "4",
  },
  {
    type: "Record",
    title: "Wing 1",
    text: "Gorseval 4 Men with no heal in 3:45",
    image: WING1,
    date: new Date(2024, 7, 15),
    recordName: "1",
  },
  {
    type: "Record",
    title: "Wing 1",
    text: "Vale Guardian 4 Men with no heal in 3:28",
    image: WING1,
    date: new Date(2024, 7, 13),
    recordName: "1",
  },
  {
    type: "Update",
    title: `"New" Card Note`,
    text: `Added a "New" card note to fresh records in order to avoid looking them upon redirection.`,
    image: Screen1,
    date: new Date(2024, 7, 13),
  },
  {
    type: "Record",
    title: "Wing 5",
    text: "Soulless Horror 3/4 Men with no heal!",
    image: WING5,
    date: new Date(2024, 7, 2024),
    recordName: "5",
  },
  {
    type: "Record",
    title: "Wing 2",
    text: "Sloth 4 Men in 3:55",
    image: WING2,
    date: new Date(2024, 5, 27),
    recordName: "2",
  },
  {
    type: "Record",
    title: "Wing 3",
    text: "Full Wing 5 Men in 22:29",
    image: WING3,
    date: new Date(2024, 5, 4),
    recordName: "3",
  },
  {
    type: "Record",
    title: "Wing 5",
    text: "Full Wing 5 Men in 24:07",
    image: WING5,
    date: new Date(2024, 3, 23),
    recordName: "5",
  },
  {
    type: "Update",
    title: "Visuals and Wingman",
    text: "A few visual adjustments ,added Wingman to home screen and fixed a bug with clickable objects.",
    image: Screen1,
    date: new Date(2024, 3, 3),
  },
  {
    type: "Record",
    title: "Wing 6",
    text: "5 Men: Wing 6 in 22:36, CA in 2:47 and Twin Largos in 5:00",
    image: WING6,
    date: new Date(2024, 2, 3),
    recordName: "6",
  },
  {
    type: "Record",
    title: "Wing 5",
    text: "Dhuum 4 Men in 8:49",
    image: WING5,
    date: new Date(2024, 1, 3),
    recordName: "5",
  },
  {
    type: "Record",
    title: "Wing 1",
    text: "Vale Guardian 3 Men + DUO kills in 16:42 and 5:33",
    image: WING1,
    date: new Date(2024, 0, 20),
    recordName: "1",
  },
  {
    type: "Record",
    title: "Wing 7",
    text: "Cardinal Adina 2 men in 13:22",
    image: WING7,
    date: new Date(2024, 0, 3),
    recordName: "7",
  },
  {
    type: "Record",
    title: "EOD Strikes",
    text: "DUO Kills of Aetherblade(13:08), Ankka Junkyard(11:31), Minister Li (23:51) and OLC(16:55)",
    image: EODStrike,
    date: new Date(2024, 0, 1),
    recordName: "EOD",
  },
  {
    type: "Record",
    title: "Wing 7",
    text: "Cardinal Adina 3 Men in 8:02 ( No Enrage! )",
    image: WING7,
    date: new Date(2023, 11, 31),
    recordName: "7",
  },
  {
    type: "Record",
    title: "Wing 7",
    text: "Cardinal Sabir 4 Men in 5:51",
    image: WING7,
    date: new Date(2023, 11, 16),
    recordName: "7",
  },
  {
    type: "Record",
    title: "IBS Strikes",
    text: "DUO Kills of Boneskinner(8:18), Voice and Claw(4:12) and Whisper of Jormag(8:59)",
    image: IBSStrike,
    date: new Date(2023, 11, 11),
    recordName: "IBS",
  },
  {
    type: "Record",
    title: "Wing 4",
    text: "Samarog 4 Men in 7:46",
    image: WING4,
    date: new Date(2023, 11, 2),
    recordName: "4",
  },
  {
    type: "Record",
    title: "SOTO Strike",
    text: "Temple of Febe - Cerus by 4 Men in 8:41",
    image: SOTOStrike,
    date: new Date(2023, 10, 27),
    recordName: "SOTO",
  },
  {
    type: "Record",
    title: "Wing 7",
    text: "Full Wing 5 Men in 26:23",
    image: WING7,
    date: new Date(2023, 10, 25),
    recordName: "7",
  },
  {
    type: "Record",
    title: "SOTO Strike",
    text: "DUO Cosmic Observatory in 17:18",
    image: SOTOStrike,
    date: new Date(2023, 10, 22),
    recordName: "SOTO",
  },
  {
    type: "Record",
    title: "SOTO Strike",
    text: "Solo Cosmic Observatory in 47:30",
    image: SOTOStrike,
    date: new Date(2023, 10, 18),
    recordName: "SOTO",
  },
  {
    type: "Update",
    title: "Clickable News Records",
    text: "News records are now clickable and will navigate to the records page to the corresponding wing or strike.",
    image: Screen1,
    date: new Date(2023, 10, 7),
  },
  {
    type: "Record",
    title: "Wing 4",
    text: "Full Wing 5 Men in 18:53",
    image: WING4,
    date: new Date(2023, 10, 4),
    recordName: "4",
  },
  {
    type: "Record",
    title: "Soulless Horror Speedrun",
    text: "Scourge abuse speedrun with LN,qT & Friends. 1:26 Kill time.",
    image: WING5,
    date: new Date(2023, 8, 26),
    recordName: "5",
  },
  {
    type: "Record",
    title: "Vale Guardian Lowmen",
    text: "Vale Guardian 3 Men in 5:31",
    image: WING1,
    date: new Date(2023, 8, 24),
    recordName: "1",
  },
  {
    type: "Update",
    title: "Weekly News Bubble",
    text: "Added a news announcer bubble when there is an update.",
    image: Screen1,
    date: new Date(2023, 8, 23),
  },
  {
    type: "Record",
    title: "IBS Strikes",
    text: "IBS Full Clear DUO",
    image: IBSStrike,
    date: new Date(2023, 8, 22),
    recordName: "IBS",
  },
  {
    type: "Record",
    title: "IBS Strike",
    text: "Fraenir of Jormag DUO in 4:34",
    image: IBSStrike,
    date: new Date(2023, 7, 22),
    recordName: "IBS",
  },
  {
    type: "Record",
    title: "Wing 2",
    text: "Full Wing 5 Men in 17:18",
    image: WING2,
    date: new Date(2023, 7, 18),
    recordName: "2",
  },
  {
    type: "Announcement",
    title: "cT Records Page",
    text: "Records Page has been added with follow links to Reddit, YouTube and Logs",
    image: Screen1,
    date: new Date(2023, 7, 9),
  },
  {
    type: "Record",
    title: "Wing 4 MO",
    text: "Mursaat Overseer TRIO in 4:21",
    image: WING4,
    date: new Date(2023, 7, 8),
    recordName: "4",
  },
  {
    type: "Announcement",
    title: "cT News Page",
    text: "News Page has been added regarding updates, records, announcement and general news",
    image: Screen1,
    date: new Date(2023, 6, 28),
  },
];

export type NewsType = "Update" | "Record" | "Announcement" | "News";

export interface INews {
  type: NewsType;
  title: string;
  text: string;
  image: string;
  date: Date;
  recordName?: string;
}
